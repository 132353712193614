import React, { useState } from "react";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { RichText } from "prismic-reactjs";

import Container from "../styles/Container";
import VideoPlayer from "./VideoPlayer";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.lightBlue};
`;

const HeadingWrapper = styled.div`
  padding-top: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const TabWrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 37px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 42px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 640px;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 37px;
  ${(p) => p.count === 1 && "margin-bottom: 0px;"}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 30px;
    ${(p) => p.count === 1 && "margin-bottom: 0px;"}
  }
`;

const CustomTab = styled(Tab)`
  font-size: 15px;
  line-height: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 22px;
    line-height: 28px;
  }
  &[data-reach-tab][data-selected] {
    font-weight: 700;
  }
  :not(:last-child) {
    margin-right: 12px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-right: 40px;
    }
  }
`;

const TabContent = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: center;
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 55%;
  }
`;
const Right = styled.div`
  margin-top: 37px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0px;
    margin-left: 60px;
    text-align: left;
    width: 40%;
  }
`;

const Title = styled.h1`
  font-family: "effra", sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 40px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 30px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 22px;
    line-height: 40px;
  }
`;

const SeriesInformation = ({ items }) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Container>
        <HeadingWrapper>
          <Heading>Series Information</Heading>
        </HeadingWrapper>
      </Container>
      <OuterContainer>
        <Container>
          <TabWrapper>
            {items && (
              <Tabs onChange={(index) => setTabIndex(index)}>
                <CustomTabList count={items.length}>
                  {items.map((item, index) => {
                    return (
                      <CustomTab key={index}>{item.tab_heading}</CustomTab>
                    );
                  })}
                </CustomTabList>
                <TabPanels>
                  {items.map((item, index) => {
                    return (
                      <TabPanel key={index}>
                        <TabContent>
                          <Left>
                            {item.season_video_link ? (
                              <VideoPlayer
                                source={item.season_video_link}
                                tabIndex={tabIndex}
                              />
                            ) : (
                              item.season_image.url(
                                <Image
                                  src={item.season_image.url}
                                  alt={item.season_image.alt}
                                />
                              )
                            )}
                          </Left>
                          <Right>
                            {item.season_title && (
                              <Title>{item.season_title}</Title>
                            )}
                            {item.season_description && (
                              <Description>
                                {RichText.render(
                                  item.season_description.richText
                                )}
                              </Description>
                            )}
                          </Right>
                        </TabContent>
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            )}
          </TabWrapper>
        </Container>
      </OuterContainer>
    </>
  );
};

export default SeriesInformation;
