import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 37px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 42px;
  }
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 20px;
  }
`;

const Series = styled.div`
  margin-bottom: 18px;
  width: calc(50% - 5px);
  :nth-child(2n + 1) {
    margin-right: 5px;
  }
  :nth-child(2n) {
    margin-left: 5px;
  }
  @media (min-width: 800px) {
    margin-bottom: 30px;
    width: calc((100% - 44px) / 3);
    :nth-child(n) {
      margin-left: 0px;
      margin-right: 0px;
    }
    :not(:nth-child(3n)) {
      margin-right: 22px;
    }
  }
`;

const LogoContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 500px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  background-color: ${(p) => p.theme.lightBlue};
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    height: 190px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 220px;
  }
`;

const Logo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 280px;
  max-height: 110px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-height: 120px;
  }
`;

const SeriesLogoList = ({ heading, items }) => {
  return (
    <Container>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        {items && (
          <SeriesContainer>
            {items.map((item, index) => {
              return (
                <Series key={index}>
                  {item.series.uid && item.series.document.data.logo.url && (
                    <Link to={`/series/${item.series.uid}`}>
                      <LogoContainer>
                        <Logo
                          src={item.series.document.data.logo.url}
                          alt={item.series.document.data.logo.alt}
                        />
                      </LogoContainer>
                    </Link>
                  )}
                </Series>
              );
            })}
          </SeriesContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default SeriesLogoList;
