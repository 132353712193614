import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import get from "lodash.get";

import NetworksHero from "../../components/NetworksHero";
import SeriesInformation from "../../components/SeriesInformation";
import SeriesLogoList from "../../components/SeriesLogoList";
import ViewNext from "../../components/ViewNext";

import Container from "../../styles/Container";
import HelmetInfo from "../../components/HelmetInfo";

const Divider = styled.div`
  border-top: 1px solid ${(p) => p.theme.lightGrey};
`;

const Series = ({ data }) => {
  const title = get(data, "prismic.series.title");

  const series = get(data, "prismic.production.body[0].fields");

  let nextSeriesTitle = undefined;
  let nextSeriesUID = undefined;

  if (Array.isArray(series)) {
    const index = series.findIndex((s) => {
      const networkTitle = get(s, "series.title");
      return networkTitle === title;
    });
    if (index != -1) {
      const length = series.length;
      if (length - 1 > index) {
        const nextSeries = series[index + 1];
        nextSeriesTitle = get(nextSeries, "series.title");
        nextSeriesUID = get(nextSeries, "series._meta.uid");
      }
    }
  }

  const productions = get(data, "prismic.distribution_index.productions");

  let index = 0;
  if (Array.isArray(productions)) {
    index = productions.findIndex((production) => {
      if (get(production, "production.title") === "Original Programming") {
        return true;
      }
    });
  }

  const nextProductionTitle = get(
    data,
    `prismic.distribution_index.productions[${index + 1}].production.title`
  );
  const nextProductionUID = get(
    data,
    `prismic.distribution_index.productions[${index + 1}].production._meta.uid`
  );

  return (
    <>
      <HelmetInfo page_data={data.prismicSeries.data} />
      <NetworksHero
        image={data.prismicSeries.data.hero_image}
        logo={data.prismicSeries.data.logo}
        text={data.prismicSeries.data.description}
        buttons={data.prismicSeries.data.buttons}
      />
      {data.prismicSeries.data.seasons[0].season_title ? (
        <SeriesInformation items={data.prismicSeries.data.seasons} />
      ) : (
        <Container>
          <Divider />
        </Container>
      )}
      <SeriesLogoList
        heading="Browse more original series"
        items={data.prismicProduction.data.body[0].items}
      />
      {nextSeriesTitle && nextSeriesUID ? (
        <ViewNext
          link={`/series/${nextSeriesUID}`}
          linkText={nextSeriesTitle}
          borderTop
        />
      ) : nextProductionTitle && nextProductionUID ? (
        <ViewNext
          link={`/productions/${nextProductionUID}`}
          linkText={nextProductionTitle}
          borderTop
        />
      ) : null}
    </>
  );
};

export default Series;

export const query = graphql`
  query ($uid: String!) {
    prismicSeries(uid: { eq: $uid }) {
      data {
        hero_image {
          url
          alt
        }
        logo {
          url
          alt
        }
        description {
          richText
        }
        title
        buttons {
          button_text
          button_link {
            url
            type
          }
        }
        seasons {
          season_description {
            richText
          }
          season_image {
            url
            alt
          }
          season_video_link
          season_title
          tab_heading
        }
      }
    }
    prismicProduction(uid: { eq: "original-programming" }) {
      data {
        body {
          __typename
          ... on PrismicProductionDataBodyOriginalSeriesList {
            items {
              series {
                uid
                document {
                  ... on PrismicSeries {
                    data {
                      title
                      logo {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicDistributionIndex {
      data {
        productions {
          production {
            uid
            document {
              ... on PrismicProduction {
                data {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
