import React from "react";
import styled from "styled-components";
import Hls from "hls.js";

const Wrapper = styled.div`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
`;

class VideoPlayer extends React.Component {
  state = {};
  componentDidMount() {
    const source = this.props.source;
    if (this.player.canPlayType("application/vnd.apple.mpegurl")) {
      //If the browser natively supports HLS
      const video = this.player;
      video.src = source;
      video.addEventListener("loadedmetadata", function () {
        video.play();
      });
    } else if (Hls.isSupported() && this.player && source) {
      const video = this.player;
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(video);
    }
  }
  componentDidUpdate() {
    const source = this.props.source;
    if (Hls.isSupported() && this.player && source) {
      const video = this.player;
      video.pause();
    }
  }

  render() {
    return (
      <Wrapper>
        <Video
          preload="none"
          ref={(player) => (this.player = player)}
          controls
        />
      </Wrapper>
    );
  }
}

export default VideoPlayer;
